import React, { useContext } from "react";
import { RealmProviderType } from "../types/Auth";
import { BooleanParam, useQueryParam } from "use-query-params";

type UserCustomData = {
  id: string;
  wallets: string[];
  user_name: string;
  avatar?: string;
  tag?: string;
  referral_code?: string;
  identities?: {
    id: string,
    provider_type: RealmProviderType
  }[]
}

type AuthContextState = {
  userCustomData: UserCustomData | null;
  referralCode: string | null;
  isLoginModelOpen: boolean;
  isRegisterModelOpen: boolean;
  isResetModelOpen: boolean;
};

type SetIsLoginModalOpenAction = {
  type: "SET_IS_LOGIN_MODAL_OPEN";
  payload: boolean;
};

type SetIsRegisterModalOpenAction = {
  type: "SET_IS_REGISTER_MODAL_OPEN";
  payload: boolean;
};

type SetIsResetModalOpenAction = {
  type: "SET_IS_RESET_MODAL_OPEN";
  payload: boolean;
};

type SetUserCustomData = {
  type: "SET_USER_CUSTOM_DATA"
  payload: UserCustomData
}

type SetReferralCode = {
  type: "SET_REFERRAL_CODE"
  payload: string | null
}

type AuthContextActions =
  | SetIsLoginModalOpenAction
  | SetIsRegisterModalOpenAction
  | SetIsResetModalOpenAction
  | SetUserCustomData
  | SetReferralCode;

type AuthContextActionValue = {
  setIsLoginModalOpen: (open: boolean) => void;
  setIsRegisterModalOpen: (open: boolean) => void;
  setIsResetModalOpen: (open: boolean) => void;
  setUserCustomData: (customData: UserCustomData) => void;
  setReferralCode: (referralCode: string | null) => void;
};

type Props = {
  children: React.ReactNode;
};

type AuthContextValues = {
  state: AuthContextState;
  actions: AuthContextActionValue;
};

const AuthContext = React.createContext<AuthContextValues>({
  state: {
    isLoginModelOpen: false,
    isRegisterModelOpen: false,
    isResetModelOpen: false,
    userCustomData: null,
    referralCode: null
  },
  actions: {
    setIsLoginModalOpen: () => {},
    setIsRegisterModalOpen: () => {},
    setIsResetModalOpen: () => {},
    setUserCustomData: () => {},
    setReferralCode: () => {}
  }
});

const reducer = (
  state: AuthContextState,
  { type, payload }: AuthContextActions
): AuthContextState => {
  switch (type) {
    case "SET_IS_LOGIN_MODAL_OPEN":
      return {
        ...state,
        isLoginModelOpen: payload,
      };
    case "SET_IS_REGISTER_MODAL_OPEN":
      return {
        ...state,
        isRegisterModelOpen: payload,
      };
    case "SET_IS_RESET_MODAL_OPEN":
      return {
        ...state,
        isResetModelOpen: payload,
      };
    case "SET_USER_CUSTOM_DATA":
      return {
        ...state,
        userCustomData: payload,
      };
    case "SET_REFERRAL_CODE":
      return {
        ...state,
        referralCode: payload,
      };
    default:
      return state;
  }
};

const AuthContextProvider: React.FC<Props> = ({ children }) => {
    
  //to set query params, so user can bookmark it
  const [loginQuery, setLoginQuery] = useQueryParam("login", BooleanParam);
  const [registerQuery, setRegisterQuery] = useQueryParam("register", BooleanParam);
  const [resetQuery, setResetQuery] = useQueryParam("reset", BooleanParam);

  const [state, dispatch] = React.useReducer(reducer, {
    isLoginModelOpen: false,
    isRegisterModelOpen: false,
    isResetModelOpen: false,
    userCustomData: null,
    referralCode: null
  });

  const actions = {
    setIsLoginModalOpen: (open: boolean) => {
      setLoginQuery(open || undefined); //undefined will remove the param
      dispatch({ type: "SET_IS_LOGIN_MODAL_OPEN", payload: open });
    },
    setIsRegisterModalOpen: (open: boolean) => {
      setRegisterQuery(open || undefined);
      dispatch({ type: "SET_IS_REGISTER_MODAL_OPEN", payload: open });
    },
    setIsResetModalOpen: (open: boolean) => {
      setResetQuery(open || undefined); //undefined will remove the param
      dispatch({ type: "SET_IS_RESET_MODAL_OPEN", payload: open });
    },
    setUserCustomData: (customData: UserCustomData) => {
      dispatch({ type: "SET_USER_CUSTOM_DATA", payload: customData });
    },
    setReferralCode: (referralCode: string | null) => {
      dispatch({ type: "SET_REFERRAL_CODE", payload: referralCode });
    }
  };

  return (
    <AuthContext.Provider value={{ state, actions }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthContextProvider, useAuth };
