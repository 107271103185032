import React, { useContext } from "react";
import { LaunchpadConfig } from "../services/types";


type ConfigContextState = {
  launchpadConfig: LaunchpadConfig | null;
};

type SetLaunchpadConfig = {
  type: "SET_LAUNCHPAD_CONFIG"
  payload: LaunchpadConfig
}

type ConfigContextActions =
  | SetLaunchpadConfig

type ConfigContextActionValue = {
    setLaunchpadConfig: (launchpadConfig: LaunchpadConfig) => void;
};

type Props = {
  children: React.ReactNode;
};

type ConfigContextValues = {
  state: ConfigContextState;
  actions: ConfigContextActionValue;
};

const ConfigContext = React.createContext<ConfigContextValues>({
  state: {
    launchpadConfig: null,
  },
  actions: {
    setLaunchpadConfig: () => {},
  }
});

const reducer = (
  state: ConfigContextState,
  { type, payload }: ConfigContextActions
): ConfigContextState => {
  switch (type) {
    case "SET_LAUNCHPAD_CONFIG":
      return {
        ...state,
        launchpadConfig: payload,
      };
    default:
      return state;
  }
};

const ConfigContextProvider: React.FC<Props> = ({ children }) => {

  const [state, dispatch] = React.useReducer(reducer, {
    launchpadConfig: null
  });

  const actions = {
    setLaunchpadConfig: (launchpadConfig: LaunchpadConfig) => {
      dispatch({ type: "SET_LAUNCHPAD_CONFIG", payload: launchpadConfig });
    },
  };

  return (
    <ConfigContext.Provider value={{ state, actions }}>
      {children}
    </ConfigContext.Provider>
  );
};

const useConfigState = () => useContext(ConfigContext);

export { ConfigContextProvider, useConfigState };
