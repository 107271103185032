import React, { useEffect, useState } from "react";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";
import { useUiState } from "../../context/UiContext";
import Footer from "../Footer";
import Navbar from "../Navbar";
import { executeImxLoginCallback, passportInstance } from "../../integrations/imx";
import { SEO } from "../../components/Seo";
import CookieConsent from "../../components/CookieConsent";
import ConnectWalletModal from "../../components/Modals/ConnectWalletModal";

const Layout = ({
  children,
  location,
}: {
  children: React.ReactNode;
  location: any;
}) => {

  //global loader
  const {
    state: { isLoading, toastState },
  } = useUiState();

  //IMX LOGIN
  const [code] = useQueryParam("code", StringParam);
  const [state] = useQueryParam("state", StringParam);

  useEffect(() => {
    console.log("IMX PASSPORT CODE AND STATE", `${code}, ${state}`);
    if (code !== undefined && state !== undefined && passportInstance) {
      executeImxLoginCallback();
    }
  }, [code, state]);
  //IMX LOGIN
  
  //referral flow
  // const { onImxLogin } = useImx();
  return (
    <>
    <ConnectWalletModal/>
    <div className="bg-black text-[#A9ADB9] text-[16px]">
      <SEO />
      <Navbar />
      {isLoading && <div id="cover-spin"></div>}
      {toastState.type !== 0 && (
        <div
          className={`toast-container ${
            toastState.type === 1 ? "bg-gray-200" : "bg-red"
          } text-gray-700 text-[1.25rem] px-6 py-4 rounded-lg shadow-lg fixed top-10 mx-3 lg:right-10 lg:mx-0 mt-14 z-[100]`}
        >
          {toastState.msg}
        </div>
      )}
      {children}
      <CookieConsent/>
      <Footer />
    </div>
    </>
  );
};

export default Layout;
