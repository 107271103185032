//@ts-nocheck
import footerLogo from "./footer-logo.png";
import hackenLogo from "./proof-by-hacken-logo.png";
import logoSvg from "./logo.svg";
import genesisComanderLogo from "./genesis_commander_logo.png";
import bannerImg from "./banner.png";
import mobileBannerImg from "./mobileBanner.png";
import plusIcon from "./plus_icon.svg";
import dropDownIcon from "./dropDown.svg";
import genesisComander from "./genesis_commander.png";
import mdBanner from "./md_banner.png"
import gamePlay from "./gameplay.png";
import roadmapImg from "./roadmap.png";
import twitterIcon from "./twitter.svg";
import discordIcon from "./discord.svg";
import websiteIcon from "./website.svg";
import youtubeIcon from "./youtube.svg";
import telegramIcon from "./telegram.svg";
import contractIcon from "./contract.svg";
import bookIconSvg from "./book.svg";
import signInIconSvg from "./sign-in.svg";
import nftImg from "./nft.png";
import sphereSvg from "./sphere.svg"
import linkSvg from "./link.svg";
import nftPlaceholder from "./nft_placeholder.jpg";
import imxChainLogo from "./imx_chain_logo.jpg";
import metamaskLogoSvg from "./metamask_logo.svg";
import passportLogoSvg from "./passport_logo.svg";
import walletConnectLogoSvg from "./walletconnect_logo.svg";
import loadingImg from "./loading.png";
import { constants } from "../../utils/constants";

//dynamic assets
const genesisComanderNftPlaceholder = `${constants.assets_uri}/nft/genesis-commander/placeholder.png`;

export {
    footerLogo,
    hackenLogo,
    logoSvg,
    genesisComanderLogo,
    bannerImg,
    mobileBannerImg,
    plusIcon,
    dropDownIcon,
    genesisComander,
    mdBanner,
    gamePlay,
    roadmapImg,
    twitterIcon,
    discordIcon,
    websiteIcon,
    telegramIcon,
    youtubeIcon,
    contractIcon,
    bookIconSvg,
    signInIconSvg,
    nftImg,
    sphereSvg,
    linkSvg,
    nftPlaceholder,
    imxChainLogo,
    metamaskLogoSvg,
    passportLogoSvg,
    walletConnectLogoSvg,
    loadingImg,
    //dynamic assets
    genesisComanderNftPlaceholder
};
