import React from "react";

interface IButtonProps {
  text: string;
  onClick: () => void;
  customClassName?: string;
  disabled?: boolean;
  isLoading?: boolean;
  loaderWithText?: boolean;
}

const Button = ({
  text,
  onClick,
  customClassName = "",
  disabled = false,
  isLoading = false,
  loaderWithText = false
}: IButtonProps) => {
  const isDisabledOrLoading = disabled || isLoading;
  return (
    <button
      className={`w-full py-2 bg-[#FFE197] rounded-[7px] focus:outline-none ${
        isDisabledOrLoading
          ? "opacity-50 cursor-not-allowed"
          : "hover:bg-yellow-400"
      } ${customClassName}`}
      onClick={isDisabledOrLoading ? undefined : onClick}
      disabled={isDisabledOrLoading}
    >
      {isLoading ? (
        <div className="flex flex-row m-auto w-fit items-center gap-2">
        {loaderWithText && <span>{text}</span>}
        <svg
          className="animate-spin h-5 w-5 text-black mx-auto"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
          ></path>
        </svg>
        </div>
      ) : (
        text
      )}
    </button>
  );
};

export default Button;
