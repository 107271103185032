import React, { useContext } from "react";
import { WalletConnectionType } from "../types/Wallet";

type WalletContextState = {
  isShowConnectWallet: boolean;
  walletAddress: string | null;
  walletConnectionType: WalletConnectionType | null;
};

type SetIsShowConnectWallet = {
  type: "SET_SHOW_CONNECT_WALLET";
  payload: boolean;
};

type SetWalletAddress = {
  type: "SET_WALLET_ADDRESS";
  payload: string | null;
};

type SetWalletConnectionType = {
  type: "SET_WALLET_CONNECTION_TYPE";
  payload: WalletConnectionType | null;
};

type WalletContextActions = SetIsShowConnectWallet | SetWalletAddress | SetWalletConnectionType;

type WalletContextActionValue = {
  setIsShowConnectWallet: (show: boolean) => void;
  setWalletAddress: (walletAddress: string | null) => void;
  setWalletConnectionType: (walletConnectionType: WalletConnectionType | null) => void;
};

type Props = {
  children: React.ReactNode;
};

type WalletContextValues = {
  state: WalletContextState;
  actions: WalletContextActionValue;
};

const WalletContext = React.createContext<WalletContextValues>({
  state: {
    isShowConnectWallet: false,
    walletAddress: null,
    walletConnectionType: null
  },
  actions: {
    setIsShowConnectWallet: () => {},
    setWalletAddress: () => {},
    setWalletConnectionType: () => {},
  },
});

const reducer = (
  state: WalletContextState,
  { type, payload }: WalletContextActions
): WalletContextState => {
  switch (type) {
    case "SET_SHOW_CONNECT_WALLET":
      return {
        ...state,
        isShowConnectWallet: payload,
      };
    case "SET_WALLET_ADDRESS":
      return {
        ...state,
        walletAddress: payload,
      };
    case "SET_WALLET_CONNECTION_TYPE":
      return {
        ...state,
        walletConnectionType: payload,
      };
    default:
      return state;
  }
};

const WalletContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, {
    isShowConnectWallet: false,
    walletAddress: null,
    walletConnectionType: null
  });

  const actions = {
    setIsShowConnectWallet: (show: boolean) => {
      dispatch({ type: "SET_SHOW_CONNECT_WALLET", payload: show });
    },
    setWalletAddress: (walletAddress: string | null) => {
      dispatch({ type: "SET_WALLET_ADDRESS", payload: walletAddress });
    },
    setWalletConnectionType: (walletConnectionType: WalletConnectionType | null) => {
      dispatch({ type: "SET_WALLET_CONNECTION_TYPE", payload: walletConnectionType });
    },
  };

  return (
    <WalletContext.Provider value={{ state, actions }}>
      {children}
    </WalletContext.Provider>
  );
};

const useWalletState = () => useContext(WalletContext);

export { WalletContextProvider, useWalletState };
