import { config } from "@imtbl/sdk";
import { immutableZkEvmTestnet } from "../integrations/imx/chain";

export const environment = {
    subgraph_url: "https://api.studio.thegraph.com/query/16975/medieval-empires-staking-mumba/version/latest",
    mongo_api_base_url: `https://eu-west-1.aws.data.mongodb-api.com/app/${process.env.GATSBY_REALM_APP_ID}/endpoint`,
    mint_api_base_url: "https://mint.staging-api.medievalempires.com",
    imx_client_id: "YCfyuvQp7KBHOtpPG9umJdoMz6UDaXnJ",
    imx_environment: config.Environment.SANDBOX,
    imx_publishable_key: "pk_imapik-test-_wpOnVPnl1kUc5VVwUeV",
    imx_chain: immutableZkEvmTestnet,
    chains: [immutableZkEvmTestnet],
    wallet_connect_project_id: "d70ed60c9168b3b691f35aea1b962c02"
}   