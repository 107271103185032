import React from "react";
//@ts-ignore
import { AuthContextProvider } from "../context/AuthContext";
import { UiContextProvider } from "../context/UiContext";
import { ConfigContextProvider } from "../context/ConfigContext";
import { WalletContextProvider } from "../context/WalletContext";

const ContextProviders = ({ children }: { children: React.ReactNode }) => {
  return (
    <WalletContextProvider>
      <AuthContextProvider>
        <UiContextProvider>
          <ConfigContextProvider>{children}</ConfigContextProvider>
        </UiContextProvider>
      </AuthContextProvider>
    </WalletContextProvider>
  );
};

export default ContextProviders;
