import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
//@ts-ignore
import { getWindow } from "../../utils";
import { footerLogo, imxChainLogo } from "../../images/general";
import { constants } from "../../utils/constants";
import ConnectWallet from "../../components/Button/ConnectWallet";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const window = getWindow();
  const isMobileDevice = window && window.innerWidth < 768; // Adjust the threshold as needed
  const isHomeScreen = window && window.location.pathname === "/";

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const onLogin = () => {
    if (isMobileDevice) {
      setIsMobileMenuOpen(false);
    }
  };

  const isNotShowNavBarInMobile =
    (isMobileDevice && isHomeScreen) || isMobileMenuOpen;

  return (
    <nav className="bg-black dark:bg-gray-900 fixed w-full z-20 top-0 start-0 ">
      <div className="flex flex-wrap items-center justify-between mx-auto py-2 px-4 sm:px-10">
        <div className="flex flex-row-reverse sm:flex-row items-center space-x-3 justify-between w-full sm:w-fit sm:order-2 sm:space-x-0">
          <ConnectWallet/>
          <span
            id="dropdownDefaultButton"
            onClick={toggleDropdown}
            className="hidden sm:inline-flex text-white bg-black border border-[#3D3D44] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-1 py-1 text-center items-center dark:focus:ring-blue-800"
            style={{ marginLeft: "12px", borderRadius: "10px" }}
          >
            <img src={imxChainLogo} alt="React Logo" className="w-[1.75rem]" />

          </span>

          {/* <div
            id="dropdown"
            className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
          >
            <ul
              className="py-2 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownDefaultButton"
            >
              <li>
                <a
                  href="#"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  Loyalty Program
                </a>
              </li>
            </ul>
          </div> */}
          <img src={footerLogo} className="w-[8rem] p-2 sm:hidden" />
          {/* <button
            data-collapse-toggle="navbar-sticky"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-sticky"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button> */}
        </div>
        <div
          className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1 gap-8"
          id="navbar-sticky"
        >
          <img src={footerLogo} className="w-[8rem] p-2 hidden sm:block" />
          <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
            <li>
              <Link
                to={constants.loyality_platform_link}
                target="_blank"
                className="block py-2 px-3 text-[#878997] rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-white md:p-0 md:dark:hover:text-white dark:text-[#878997] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                aria-current="page"
              >
                Loyalty Program
              </Link>
            </li>
            <li>
              <Link
                to={constants.main_app_routes.marketplace.home}
                target="_blank"
                className="block py-2 px-3 text-[#878997] rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-white md:p-0 md:dark:hover:text-white dark:text-[#878997] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >
                Marketplace
              </Link>
            </li>
            <li>
              <Link
                to={constants.web_link}
                target="_blank"
                className="block py-2 px-3 text-[#878997] rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-white md:p-0 md:dark:hover:text-white dark:text-[#878997] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >
                Play Game
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
