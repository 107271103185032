import React, { useContext } from "react";
import { ToastState, ToastType } from "../types/UI";

type UiContextState = {
  isLoading: boolean;
  toastState: ToastState;
};

type SetIsLoading = {
  type: "SET_IS_LOADING";
  payload: boolean;
};

type SetToastState = {
  type: "SET_TOAST_STATE";
  payload: ToastState;
};

type UiContextActions = SetIsLoading | SetToastState;

type UiContextActionValue = {
  setIsLoading: (isLoading: boolean) => void;
  showToast: (toastState: ToastState, duration?: number) => void;
};

type Props = {
  children: React.ReactNode;
};

type UiContextValues = {
  state: UiContextState;
  actions: UiContextActionValue;
};

const UiContext = React.createContext<UiContextValues>({
  state: {
    isLoading: false,
    toastState: {
      msg: "",
      type: ToastType.DISABLED,
    },
  },
  actions: {
    setIsLoading: () => {},
    showToast: () => {},
  },
});

const reducer = (
  state: UiContextState,
  { type, payload }: UiContextActions
): UiContextState => {
  switch (type) {
    case "SET_IS_LOADING":
      return {
        ...state,
        isLoading: payload,
      };
    case "SET_TOAST_STATE":
      return {
        ...state,
        toastState: payload,
      };
    default:
      return state;
  }
};

const UiContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, {
    isLoading: false,
    toastState: {
      msg: "",
      type: ToastType.DISABLED,
    },
  });

  const actions = {
    setIsLoading: (isLoading: boolean) => {
      dispatch({ type: "SET_IS_LOADING", payload: isLoading });
    },
    showToast: (toastState: ToastState, duration: number = 5000) => {
      dispatch({ type: "SET_TOAST_STATE", payload: toastState });
      setTimeout(() => { //reseting after duration, to hide toast msg
        dispatch({ type: "SET_TOAST_STATE", payload: { type: ToastType.DISABLED, msg: "" } });
      }, duration);
    },
  };

  return (
    <UiContext.Provider value={{ state, actions }}>
      {children}
    </UiContext.Provider>
  );
};

const useUiState = () => useContext(UiContext);

export { UiContextProvider, useUiState };
