import React from "react";
//@ts-ignore
import { RealmAppProvider } from "../utils/RealmApp";
import { QueryParamProvider } from "use-query-params";
import { ReachAdapter } from "use-query-params/adapters/reach";
import ContextProviders from "./ContextProviders";
import { ChakraProvider } from "@chakra-ui/react";
import chakraTheme from "../themes/chakra";
import { RainbowKit } from "../hoc/RainbowKit";

const AppProviders = ({ children }: { children: React.ReactNode }) => {
  return (
    <QueryParamProvider adapter={ReachAdapter}>
      <ChakraProvider theme={chakraTheme}>
        <RainbowKit>
          <ContextProviders>{children}</ContextProviders>
        </RainbowKit>
      </ChakraProvider>
    </QueryParamProvider>
  );
};

export default AppProviders;
