import React from "react";
import {
  getDefaultConfig,
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme
} from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
  immutableZkEvmTestnet,
} from "wagmi/chains";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { environment } from "../utils/environment";
import {
  phantomWallet,
  trustWallet,
  magicEdenWallet,
  coinbaseWallet,
  bybitWallet,
  bitgetWallet,
  braveWallet,
  uniswapWallet,
  argentWallet,
  bitverseWallet,
  krakenWallet,
  mewWallet,
} from "@rainbow-me/rainbowkit/wallets";

const projectId = environment.wallet_connect_project_id;

const chains = environment.chains;

const defaultWallets = getDefaultWallets();

const config = getDefaultConfig({
  appName: "Medieval Empires",
  projectId,
  chains: [mainnet, polygon, optimism, arbitrum, base, immutableZkEvmTestnet],
  ssr: true,
  wallets: [
    {
      groupName: "Other Wallets",
      wallets: [
        coinbaseWallet,
        phantomWallet,
        trustWallet,
        magicEdenWallet,
        bybitWallet,
        bitgetWallet,
        braveWallet,
        uniswapWallet,
        argentWallet,
        bitverseWallet,
        krakenWallet,
        mewWallet,
      ],
    },
    ...defaultWallets.wallets,
  ],
});

const queryClient = new QueryClient();

export const RainbowKit = ({ children }: { children: React.ReactNode }) => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider modalSize="compact" theme={darkTheme()}>{children}</RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};
