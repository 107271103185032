import React, { useEffect, useState } from "react";
import {
  getImxWalletAddress,
  imxLogout,
  isImxLoggedIn,
} from "../integrations/imx";
import { useUiState } from "../context/UiContext";
import { WalletConnectionType } from "../types/Wallet";
import { useAccount, useSignMessage, useDisconnect } from "wagmi";
import { useWalletState } from "../context/WalletContext";

const useConnectWallet = () => {
  const {
    actions: {
      setWalletAddress,
      setWalletConnectionType,
      setIsShowConnectWallet,
    },
    state: { walletAddress, walletConnectionType },
  } = useWalletState();
  const {
    state: { isLoading },
    actions: { setIsLoading },
  } = useUiState();

  const {
    address: eoaWalletAddress,
    isConnected: isEoaWalletConnected,
    isConnecting: isEoaWalletConnecting,
    isReconnecting: isEoaWalletReConnecting,
  } = useAccount();

  const { disconnect: disconnectEoaWallet } = useDisconnect();

  const {
    data,
    isError,
    isSuccess,
    signMessageAsync: walletConnectSignMessage,
  } = useSignMessage();

  const WALLET_CONNECTION_TYPE_PERSIST_KEY = "WALLET_CONNECTION_TYPE";

  //rainbowkit connection states
  useEffect(() => {
    const checkEoaConnection = async () => {
       //handle if passport connect and somehow this runs, avoid overlapping
      const isPassportLoggedIn = await isImxLoggedIn();
      const persistedConnectType = getPersistedConnectionType();
      if (persistedConnectType == WalletConnectionType.PASSPORT || isPassportLoggedIn) return;
      if (eoaWalletAddress) {
        setWalletAddress(eoaWalletAddress);
        setWalletConnectionType(WalletConnectionType.EOA_WALLET);
        closeConnectWalletModal();
      } else if (walletConnectionType === WalletConnectionType.EOA_WALLET) {
        resetState();
      }
    }
    checkEoaConnection();
  }, [eoaWalletAddress, isEoaWalletConnected]);

  useEffect(() => {
    //if connecting close the connection modal
    // this is for the case if wallet connect QR opened
    if (isEoaWalletConnecting || isEoaWalletReConnecting) {
      closeConnectWalletModal();
    }
    //if wallet reconnecting show the loader
    if (isEoaWalletReConnecting) {
      setIsLoading(true);
    }
    //hack in metamask case, starting loader on component level
    //it will stop the loader if connected
    if (isEoaWalletConnected) {
      setIsLoading(false);
    }
  }, [isEoaWalletConnecting, isEoaWalletReConnecting, isEoaWalletConnected]);
  //rainbowkit connection states

  useEffect(() => {
    //persist the wallet connection type to re initiate on refresh
    if (walletConnectionType) {
      localStorage.setItem(
        WALLET_CONNECTION_TYPE_PERSIST_KEY,
        walletConnectionType
      );
    }
  }, [walletConnectionType]);

  useEffect(() => {
    const initConnection = async () => {
      //reinitate the passport wallet on refresh
      const connectionType = getPersistedConnectionType();
      if (connectionType == WalletConnectionType.PASSPORT) {
        const isPassportLoggedIn = await isImxLoggedIn();
        if (isPassportLoggedIn) {
          await connectPassport();
        } else {
          //if not logged in reset the state
          resetState();
        }
      }
    };
    initConnection();
  }, []);

  const getPersistedConnectionType = () => {
    return localStorage.getItem(
      WALLET_CONNECTION_TYPE_PERSIST_KEY
    ) as WalletConnectionType | null;
  };

  const connectPassport = async () => {
    setIsLoading(true);
    try {
      const passportWalletAddress = await getImxWalletAddress();
      setWalletAddress(passportWalletAddress);
      setWalletConnectionType(WalletConnectionType.PASSPORT);
      closeConnectWalletModal();
    } catch (error) {
      console.log("Error while connecting to passport", error);
    }
    setIsLoading(false);
  };

  const signMessage = async (message: string) => {
    try {
      const signature = await walletConnectSignMessage({ message });
      return signature;
    } catch (error) {
      console.log("error while signing", error);
    }
  };

  const openConnectWalletModal = () => {
    setIsShowConnectWallet(true);
  };

  const closeConnectWalletModal = () => {
    setIsShowConnectWallet(false);
  };

  const resetState = () => {
    //remove from localstorage as well
    localStorage.removeItem(WALLET_CONNECTION_TYPE_PERSIST_KEY);
    setWalletAddress(null);
    setWalletConnectionType(null);
  };

  const disconnect = async () => {
    setIsLoading(true);
    try {
      if (walletConnectionType === WalletConnectionType.PASSPORT) {
        await imxLogout();
      } else {
        disconnectEoaWallet();
      }
      resetState();
    } catch (error) {
      console.log("Error while disconnecting wallet", error);
    }
    setIsLoading(false);
  };

  const isConnected =
    walletConnectionType == WalletConnectionType.PASSPORT
      ? !!walletAddress //need to find workaroud to check if passport connected
      : isEoaWalletConnected;

  return {
    walletAddress,
    walletConnectionType,
    connectPassport,
    isConnected,
    openConnectWalletModal,
    signMessage,
    disconnect,
  };
};

export default useConnectWallet;
