import React from "react";
import useConnectWallet from "../../hooks/useConnectWallet";
import Button from ".";
import { hideWalletAddress } from "../../utils";
import { GoCopy } from "react-icons/go";

const ConnectWallet = () => {
  const { walletAddress, disconnect, isConnected, openConnectWalletModal } =
    useConnectWallet();

  const onCopyWallet = () => {
    navigator.clipboard.writeText(walletAddress as string);
  };

  return (
    <>
      {(!isConnected || !walletAddress) ? (
        <div className="w-[10rem]">
          <Button
            text="Connect Wallet"
            customClassName="text-center text-gray-800 font-semibold"
            onClick={openConnectWalletModal}
          />
        </div>
      ) : (
        <div className="flex flex-row gap-1 sm:gap-4">
        <div className="block relative text-white font-medium rounded-lg text-sm px-4 py-2 text-center bg-[#2a2929] cursor-pointer">
          <span>{hideWalletAddress(walletAddress as string)}</span>
          <div className="absolute top-0 left-0 w-full h-full bg-yellow-400 rounded-lg flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
            <button
              type="button"
              className="text-[#1A1A1D] font-medium rounded-lg text-sm px-4 py-2"
              onClick={onCopyWallet}
            >
              <div className="flex flex-row gap-1 font-bold">
                <span>Copy</span>
                <GoCopy
                  color="black"
                  className="cursor-pointer text-[1.25rem]"
                />
              </div>
            </button>
          </div>
        </div>
        <Button text="Disconnect" customClassName="text-center text-gray-800 font-bold px-4" onClick={disconnect}/>
        </div>
      )}
    </>
  );
};

export default ConnectWallet;
