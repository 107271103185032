import React, { useEffect, useState } from "react";
import { Modal, ModalContent, ModalOverlay, theme } from "@chakra-ui/react";
//@ts-ignore
import useConnectWallet from "../../hooks/useConnectWallet";
import { useWalletState } from "../../context/WalletContext";
import Button from "../../components/Button";
import {
  metamaskLogoSvg,
  passportLogoSvg,
  walletConnectLogoSvg,
} from "../../images/general";
import { WalletButton } from "@rainbow-me/rainbowkit";
import { useUiState } from "../../context/UiContext";

function ConnectWalletModal() {
  const {
    actions: { setIsShowConnectWallet },
    state: { isShowConnectWallet },
  } = useWalletState();

  const {
    actions: { setIsLoading },
  } = useUiState();
  const { connectPassport } = useConnectWallet();

  const [isShowOtherWallets, setIsShowOtherWallets] = useState(false);

  const otherWallets = [
    "Phantom",
    "Trust",
    "Magic Eden",
    "Coinbase",
    "Bybit",
    "Bitget",
    "Brave",
    "Uniswap",
    "Argent",
    "Bitverse",
    "Kraken",
    "Mew",
  ];

  const onClose = () => {
    setIsShowConnectWallet(false);
  };

  //refresh state on close
  useEffect(() => {
    if(!isShowConnectWallet) {
      setIsShowOtherWallets(false);
    }
  },[isShowConnectWallet])

  const walletConnectionType = (
    name: string,
    onClick: () => void | Promise<void>,
    icon: any = null
  ) => {
    return (
      <div className="flex flex-row justify-between rounded-lg border border-[#3D3D44] py-4 pr-4 items-center bg-black">
        {icon && (
          <img src={icon} className="flex-[1] h-[2rem] w-[2rem] -ml-2" />
        )}
        <span className="text-[#F7F7F8] flex-[2] font-fira-sans text-[18px] font-semibold">
          {name}
        </span>
        <div className="flex-[1]">
          <Button
            text="Connect"
            customClassName="text-center text-gray-800 font-semibold"
            onClick={onClick}
          />
        </div>
      </div>
    );
  };

  const otherWalletConnectionType = (
    name: string,
    onClick: () => void | Promise<void>
  ) => {
    return (
      <div className="flex flex-row justify-between rounded-lg border border-[#3D3D44] py-4 pr-4 items-center bg-black">
        <span className="text-[#F7F7F8] font-fira-sans text-[18px] font-semibold w-[20rem] px-[2rem]">
          {name}
        </span>
        <div className="w-[10rem]">
          <Button
            text="Connect"
            customClassName="text-center text-gray-800 font-semibold"
            onClick={onClick}
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={isShowConnectWallet}
      closeOnOverlayClick={false}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        bgColor={"#1A1A1D"}
        className="border rounded-lg border-[#3D3D44]"
      >
        <div className="flex flex-col p-5 gap-5 w-full">
          <div className="flex flex-row justify-between items-center">
            <span className="text-[#F7F7F8] font-fira-sans text-2xl font-semibold">
              Connect your wallet
            </span>
            <span
              onClick={onClose}
              className="text-[#F7F7F8] cursor-pointer font-fira-sans text-xl font-bold"
            >
              X
            </span>
          </div>
          <div className="flex flex-col gap-2">
            {walletConnectionType(
              "Immutable Passport",
              connectPassport,
              passportLogoSvg
            )}
            <WalletButton.Custom wallet="metamask">
              {({ ready, connect }) => {
                return (
                  <>
                    {walletConnectionType(
                      "Metamask",
                      async () => {
                        setIsLoading(true);
                        await connect().finally(() => {
                          //in case rejected
                          setIsLoading(false);
                          //connected will be handle from useConnectWallet Hook
                        });
                      },
                      metamaskLogoSvg
                    )}
                  </>
                );
              }}
            </WalletButton.Custom>
            <WalletButton.Custom wallet="walletconnect">
              {({ ready, connect }) => {
                return (
                  <>
                    {walletConnectionType(
                      "Wallet Connect",
                      connect,
                      walletConnectLogoSvg
                    )}
                  </>
                );
              }}
            </WalletButton.Custom>
          </div>
          <span
            onClick={() => setIsShowOtherWallets((prev) => !prev)}
            className="text-[#FFE197] font-fira-sans text-xl underline hover:cursor-pointer text-right"
          >
            {isShowOtherWallets ? "hide" : "show"} other wallets
          </span>
          {isShowOtherWallets && (
            <div className="flex flex-col gap-2 overflow-auto max-h-[15rem]">
              {otherWallets.map((eachOtherWallet) => {
                const walletIdentifier = eachOtherWallet
                  ?.toLowerCase()
                  ?.replace(" ", "");
                return (
                  <WalletButton.Custom wallet={walletIdentifier}>
                    {({ ready, connect, ...rest }) => {
                      return (
                        <>
                          {otherWalletConnectionType(
                            `${eachOtherWallet} Wallet`,
                            connect
                          )}
                        </>
                      );
                    }}
                  </WalletButton.Custom>
                );
              })}
            </div>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
}

export default ConnectWalletModal;
